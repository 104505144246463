import "../../scss/theme.scss";
import * as bootstrap from "bootstrap";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

    var swiper_default = new Swiper(".swiper--default", {
        loop: true,
        scrollbar: {
          el: ".swiper-scrollbar",
        },
        pagination: {
          el: ".swiper-pagination-default",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }
    });
    
    var bestSellers = new Swiper("#swiper-cont", {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 20,
      autoplay: {
        delay: 6000,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      },
    });

    var swiper = new Swiper('.swiper-container-home', {
      slidesPerView: 1,
      spaceBetween: 0,
      allowTouchMove: true,
      breakpoints: {
        992: {
          slidesPerView: 3,
          spaceBetween: 0,
          allowTouchMove: false, 
        },
      },
    });
    
    var gallerySwiper = new Swiper('.section__gallery__wrapper .swiper-container-logos', {
      loop: false,
      slidesPerView: 4,
      spaceBetween: 40,
      pagination: {
        el: '.swiper-pagination-gallery',
      },
      breakpoints: {
        768: {
          slidesPerView: 4,
          spaceBetween: 24,
        },
        992: {
          slidesPerView: 8,
          spaceBetween: 40,
        },
      }
    });

import { initializeNavbarTogglers, initializeDropdownToggle, initializeSidebarToggle, checkWindowSizeOnLoad, handleResizeEvents } from './uiHandlers';

jQuery(document).ready(function ($) {
  initializeNavbarTogglers($);
  initializeDropdownToggle($);
  initializeSidebarToggle($);
  checkWindowSizeOnLoad($);

  // Gestisce gli eventi di ridimensionamento
  $(window).resize(function() {
    handleResizeEvents($);
  });
});